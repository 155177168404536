
















































































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  NavBar,
  Icon,
  Button,
  Image,
  Swipe,
  SwipeItem,
  Tabs,
  Tab,
  Popup,
  Stepper,
  Dialog,
  Checkbox,
  Field,
} from "vant";
import { ProjectDetailsCouponDetailes } from "@/shims-video";
import TicketItem from "@/components/Tickets/Ticketltem.vue";
import { defaultError } from "@/plugins/axios";
import { monitorEvent } from "@/utils/youMengMonitor";

@Component({
  components: {
    Mixin,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [Image.name]: Image,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [Popup.name]: Popup,
    [Checkbox.name]: Checkbox,
    [Stepper.name]: Stepper,
    [Dialog.Component.name]: Dialog.Component,
    [Field.name]: Field,
    TicketItem,
  },
})
export default class List extends Mixins(Mixin) {
  public couponslist: Array<ProjectDetailsCouponDetailes> = [];
  @Prop({ default: Array })
  public orderCouponList!: Array<ProjectDetailsCouponDetailes>;
  @Prop({ default: Array })
  public unusableCouponList!: Array<ProjectDetailsCouponDetailes>;
  @Prop({ default: false })
  public orderCouponListshow!: boolean;

  @Prop({ default: {} })
  public checkCoupon!: ObjectMap;

  public checkItem: any = {};
  public checkStatus = false;
  public showAddCoupon = false;
  public couponCode = "";

  @Watch("unusableCouponList")
  changeUnusableCouponList(val: Array<ProjectDetailsCouponDetailes>): void {
    this.unusableCouponList = val;
  }

  created(): void {
    this.orderCouponListshow = this.orderCouponListshow;
    this.checkItem = Object.assign({}, this.checkCoupon);
  }

  /*
   * 处理价格
   */
  dealPrice(price?: number): string {
    return price ? `￥${price}` : "";
  }

  /*
   * 订单金额提示
   */
  dealAmount(type?: number, Amount?: number): string {
    let name = "";
    if (type === 1) {
      name = "不限金额";
    } else {
      name = `满${Amount}可用`;
    }
    return name;
  }

  /*
   * 处理日期
   */
  dealDate(
    type?: number,
    endTimeStr?: string,
    startTimeStr?: string,
    startTime?: string,
    endTime?: string,
    effectiveDays?: number
  ): string {
    let timer = "";
    let nowTime = new Date().getTime();
    if (type === 1) {
      if (startTime && endTime) {
        if (Number(nowTime) > Number(startTimeStr)) {
          timer = `有效期至${this.formDate(String(endTimeStr))}`;
        } else {
          timer = `有效期${startTime}至${endTime}`;
        }
      } else {
        timer = "";
      }
    } else {
      if (effectiveDays) {
        timer = `${effectiveDays}天后过期`;
      } else {
        timer = "";
      }
    }

    return timer;
  }

  //可用优惠券  用户 选中
  choose(check?: boolean, item?: ProjectDetailsCouponDetailes): void {
    this.checkStatus = check!;
    this.checkItem = item;
  }
  choseCoupon(): void {
    if (this.checkStatus === true) {
      this.$emit("checkitem", this.checkItem);
    } else {
      this.$emit("checkitem", {});
    }
    this.$emit("close");
  }
  onClickLeft(): void {
    if (JSON.stringify(this.checkItem) != "{}") {
      this.$emit("checkitem", this.checkItem);
    }

    this.$emit("close");
  }
  godetail(item: { couponId: number }): void {
    this.$router.push(`/coupon-detail/${item.couponId}`);
  }
  addCoupon(): void {
    this.$api.goodApi.coupon.exchangeCoupon(
      this.couponCode.trim(),
      ({ data }) => {
        if (data) {
          monitorEvent("ViewOrder_AddOfferSuccess", "成功添加优惠券"); // 埋点：确认订单，成功添加优惠券
          this.couponCode = "";
          this.$emit("refreshCoupon");
          this.showAddCoupon = false;
        }
      },
      (error) => {
        monitorEvent("ViewOrder_AddOfferFail", "优惠券添加失败"); // 埋点：确认订单，优惠券添加失败
        defaultError(error);
      }
    );
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");
    // const ss = (dt.getSeconds() + "").padStart(2, "0");

    return `${y}.${m}.${d}  ${hh}:${mm}`;
  }
}
